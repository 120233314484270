import { GoogleCharts } from 'google-charts';
import { drawMonthlyChart } from './monthlyChart';
import { drawMonthlyTable } from './monthlyTable';
import { drawTotalChart } from './totalChart';

function drawSummary(annualTurnovers, totalTurnovers, turnoverType, divType) {
  const data = new GoogleCharts.api.visualization.DataTable();
  data.addColumn('number', gettext('Month'));

  const rows = [
    [{ v: 1, f: gettext('January') }], [{ v: 2, f: gettext('February') }],
    [{ v: 3, f: gettext('March') }], [{ v: 4, f: gettext('April') }],
    [{ v: 5, f: gettext('May') }], [{ v: 6, f: gettext('June') }],
    [{ v: 7, f: gettext('July') }], [{ v: 8, f: gettext('August') }],
    [{ v: 9, f: gettext('September') }], [{ v: 10, f: gettext('October') }],
    [{ v: 11, f: gettext('November') }], [{ v: 12, f: gettext('December') }],
  ];

  const years = Object.keys(annualTurnovers);
  const lastRow = [
    {
      v: 13,
      f: gettext('Total'),
      p: {
        className: turnoverType === 'monthly' ? divType.concat('MonthlyTotalLabel') : divType.concat('CummulativeTotalLabel'),
      },
    },
  ];

  years.forEach((year) => {
    data.addColumn('number', year);

    const annualTurnover = annualTurnovers[year];
    rows.forEach((row, rowIndex) => {
      row.push(annualTurnover.netClp[rowIndex]);
    });
    lastRow.push({
      v: totalTurnovers[year].netClp,
      p: { className: 'totalCell' },
    });
  });

  const formatter = new GoogleCharts.api.visualization.NumberFormat({
    prefix: '$',
    groupingSymbol: '.',
    fractionDigits: 0,
  });

  data.addRows(rows);

  drawMonthlyChart(data, turnoverType, divType);

  for (let j = 1; j <= (data.getNumberOfColumns() - 1); j += 1) {
    formatter.format(data, j);
  }

  setTimeout(() => {
    data.addRow(lastRow);
    for (let j = 1; j <= (data.getNumberOfColumns() - 1); j += 1) {
      formatter.format(data, j);
    }
    drawMonthlyTable(data, turnoverType, divType);
  }, 100);
}

function renderError(divType, error) {
  const element = document.getElementById(divType.concat('_monthly_table_div'));
  element.innerHTML = `<div class="alert alert-danger">${error}</div>`;
}

function callAnnualTurnovers(apiUrl, divType, callback) {
  fetch(apiUrl)
    .then((response) => {
      if (!response.ok) {
        renderError(divType, 'API fetch error');
        return 1;
      }

      return response.json().then((annualTurnovers) => {
        GoogleCharts.load(() => {
          drawSummary(
            annualTurnovers.annualTurnovers,
            annualTurnovers.totalTurnovers,
            'monthly',
            divType,
          );

          drawSummary(
            annualTurnovers.cummulativeTurnovers,
            annualTurnovers.totalTurnovers,
            'cummulative',
            divType,
          );

          drawTotalChart(annualTurnovers.totalTurnovers, divType);
        });
      });
    }).then(callback)
    .catch((error) => {
      renderError(divType, error);
    });
}

$(() => {
  if (!document.getElementById('turnoverNavTabs')) {
    return;
  }

  callAnnualTurnovers('/api/v1/annual-turnovers/', 'real', () => {
    callAnnualTurnovers('/api/v1/projected-annual-turnovers/', 'projected');
  });
});
