import '../scss/main.scss';
import App from './app';

import './behaviors/status';
import './behaviors/forms';
import './behaviors/etds-form';
import './behaviors/input-time-picker';
import './behaviors/input-rut';
import './behaviors/confirmation';
import './charts/turnoverApiCall';
import './charts/billingHealthReport';
import './costcentres/assignmentForm';
import './costcentres/tree';
import './tables/table';
import './conciliation/loadForm';

$(() => {
  $('.alert').each((i, item) => {
    App.utils.highlight($(item));
  });

  const $alert = $('.main-alert .alert');
  setTimeout(() => $alert.fadeOut(), 10000);

  $('[data-toggle="tooltip"]').tooltip();
});
