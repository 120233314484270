import App from '../app';

$(() => {
  const select = $('#id_movement_filter');
  const selectData = document.getElementById('id_movement_filter');
  const modelSelect = $('#id_account_movement');
  const isUpdate = $('#id_is_update');

  function resetField(values) {
    const movements = values[selectData.value];
    modelSelect.empty();
    for (const { id, description } of movements) {
      modelSelect.append(`<option value=${id}>${description}</option>`);
    }
  }

  async function loadValuesInForm(values, initial) {
    resetField(values);
    modelSelect.val(initial);
    modelSelect.trigger('change');
  }

  function callAccountMovements(initial = null) {
    modelSelect.addClass('disabled').prop('disabled', true);
    App.utils.showLoading(modelSelect);
    fetch(`/api/v1/account-movements/${selectData.value}`)
      .then((response) => {
        if (!response.ok) {
          modelSelect.removeClass('disabled').prop('disabled', false);
          App.utils.hideLoading();
          throw new Error('API fetch error');
        }
        modelSelect.removeClass('disabled').prop('disabled', false);
        App.utils.hideLoading();
        return response.json().then((accountMovements) => {
          loadValuesInForm(accountMovements, initial);
        });
      }).catch((error) => {
        window.alert(error); // eslint-disable-line no-alert
      });
  }

  function getUrl() {
    return window.location.href;
  }


  function callInitial(pk) {
    fetch(`/api/v1/conciliation-update-initial/${pk}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('initial API fetch error');
        }
        return response.json().then(({ initial, type }) => {
          select.val(type);
          select.trigger('change');

          callAccountMovements(initial);
        });
      }).catch((error) => {
        window.alert(error); // eslint-disable-line no-alert
      });
  }

  function getPk(url) {
    return url[5];
  }

  $(document).ready(() => {
    if (isUpdate.val() === 'True') {
      const url = getUrl().split('/');
      const pk = getPk(url);
      const conciliationId = parseInt(pk, 10);

      callInitial(conciliationId);
    } else if (selectData != null) {
      callAccountMovements();
    }
  });


  select.change(() => {
    callAccountMovements();
  });
});
