import App from '../app';

const select2UsedSelectors = [];

function connectSelects(selectors) {
  const childSelector = selectors.child;
  const $childSelect = $(childSelector);
  const parentSelectors = selectors.parents;
  const tags = $childSelect.hasClass('js-select2-tags');

  const lookups = selectors.lookupNames || [];

  const { url } = selectors;

  if (!$childSelect.length) {
    return;
  }

  for (let i = 0; i < parentSelectors.length; i += 1) {
    const $parent = $(parentSelectors[i]);

    if ($parent.length === 0) {
      return;
    }

    $parent.change(() => {
      $childSelect.val(null).trigger('change');
    });

    if (lookups.length <= i) {
      lookups.push($parent.attr('name'));
    }
  }
  $childSelect.select2({
    width: '100%',
    ajax: {
      url,
      dataType: 'json',
      delay: 250,
      data: (params) => {
        const data = {};

        parentSelectors.forEach((parentSelector, index) => {
          let value = $(parentSelector).val();
          if (value.join) {
            value = value.join(',');
          }
          data[lookups[index]] = value;
        });

        return {
          ...data,
          q: params.term,
        };
      },
      processResults: data => ({ results: data }),

      cache: true,
    },
    tags,
    theme: 'bootstrap4',
  });

  // mark selects as already used for select2
  select2UsedSelectors.push(childSelector);
}

$(() => {
  // Declare conected selects
  const connectedSelectsData = [{
    parents: ['#id_region'],
    child: '#id_commune',
    url: '/regions/communes/search/',
  }, {
    parents: ['#id_person'],
    child: '#id_project_stage',
    url: '/projects/stages/search/',
  }, {
    parents: ['#id_project_stage'],
    child: '#id_milestone',
    url: '/projects/milestones/search/',
  }];

  // and connect them
  connectedSelectsData.forEach((connectedSelectData) => {
    connectSelects(connectedSelectData);
  });

  setTimeout(() => {
    $('select').not('.js-not-select2').not(select2UsedSelectors.join()).each((i, select) => {
      const $select = $(select);
      const tags = $select.hasClass('js-select2-tags');
      $select.select2({
        theme: 'bootstrap4',
        tags,
      });
    });
  }, 100);

  $('.model-form input:text').addClass('form-control');

  $('form').submit((e) => {
    const $this = $(e.currentTarget);
    const $buttons = $this.find(':submit').not('.js-do-not-disable-on-submit');

    // disable buttons after submit to prevent disabling submit inputs
    // with values
    setTimeout(() => {
      $buttons.addClass('disabled').prop('disabled', true);
      App.utils.showLoading($buttons);

      setTimeout(() => {
        $buttons.removeClass('disabled').prop('disabled', false);
        App.utils.hideLoading();
      }, 3000);
    }, 10);

    return true;
  });
});

export default connectSelects;
