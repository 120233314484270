import { GoogleCharts } from 'google-charts';
import { options } from './generalOptions';


export function drawTotalChart(annualTurnovers, divType) {
  const rows = [[gettext('Year'), gettext('Net CLP'), gettext('VAT CLP'), { role: 'style' }]];
  const years = Object.keys(annualTurnovers);
  const colors = ['#76CCDF', '#28AFCE', '#1A7084'];

  years.forEach((year, yearIndex) => {
    rows.push([
      year,
      annualTurnovers[year].netClp,
      annualTurnovers[year].vatClp,
      colors[yearIndex],
    ]);
  });

  // eslint-disable-next-line new-cap
  const data = new GoogleCharts.api.visualization.arrayToDataTable(rows);

  const view = new GoogleCharts.api.visualization.DataView(data);
  view.setColumns(
    [0, 1, {
      calc: 'stringify',
      sourceColumn: 1,
      type: 'string',
      role: 'annotation',
    }, 2],
  );

  const formatter = new GoogleCharts.api.visualization.NumberFormat({
    prefix: '$',
    groupingSymbol: '.',
    fractionDigits: 0,
  });

  formatter.format(data, 1);

  const totalChartOptions = {
    ...options,
    hAxis: {
      ...options.hAxis,
      title: gettext('Years'),
    },
    vAxis: {
      ...options.vAxis,
      format: '$#,###',
      minValue: 0,
    },
    legend: { position: 'none' },
    title: gettext('Revenue by Year'),
    isStacked: true,
  };

  const chart = new GoogleCharts.api.visualization.ColumnChart(document.getElementById(divType.concat('_total_chart_div')));
  chart.draw(data, totalChartOptions);
}
