import 'datatables.net-bs4';

const formatter = new Intl.NumberFormat('de-DE');

function createRow(movement) {
  const debitAmount = formatter.format(movement.debitAmountClp);
  const creditAmount = formatter.format(movement.creditAmountClp);
  let documentDescription;
  if (movement.documentId) {
    documentDescription = `
      <a href="/documents/${movement.documentId}">
        ${movement.description}
      </a>`;
  } else {
    documentDescription = movement.description;
  }

  const row = `
    <tr>
        <td>${documentDescription}</td>
        <td class="text-nowrap">$ ${debitAmount}</td>
        <td class="text-nowrap">$ ${creditAmount}</td>
    </tr>
    `;
  return row;
}

function format(rowData) {
  const { movements } = rowData;
  const rowsArray = movements.map(movement => createRow(movement));
  const rows = rowsArray.join('\n');
  const str = `
    <table class="table table-striped">
        <thead>
            <th> Description </th>
            <th> Income </th>
            <th> Outcome </th>
        </thead>
        <tbody>
            ${rows}
        </tbody>
    </table>
  `;
  return str;
}

async function getCashflow(pastMonths, futureMonths) {
  return fetch(`/api/v1/cashflow/?past_months=${pastMonths}&future_months=${futureMonths}`)
    .then((response) => {
      if (!response.ok) {
        throw new Error('API fetch error');
      }
      return response.json().then(cashflow => cashflow.data);
    }).catch((error) => {
      window.alert(error); // eslint-disable-line no-alert
    });
}

$(async () => {
  if (document.getElementsByClassName('cashflow-table').length === 0) {
    return;
  }
  let $todayRow;

  const urlParams = new URLSearchParams(window.location.search);
  const pastMonths = urlParams.get('past_months');
  const futureMonths = urlParams.get('future_months');
  const data = await getCashflow(pastMonths, futureMonths);
  const table = $('table.cashflow-table').DataTable({
    createdRow(row, rowData) {
      if (rowData.balance < 0) {
        $(row).addClass('table-danger');
      }
      if (rowData.today) {
        $todayRow = $(row);
        $todayRow.addClass('table-success');
      }
    },
    data,
    language: {
      thousands: '.',
    },
    columns: [{
      data: 'date',
      className: 'columnRight',
    },
    {
      data: 'debitAmountClp',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight text-success',
    },
    {
      data: 'creditAmountClp',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight text-danger',
    },
    {
      data: 'balance',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight',
    },
    {
      data: 'accountsReceivable',
      render: $.fn.dataTable.render.number('.', ',', 0, '$ '),
      className: 'columnRight',
      createdCell(td, cellData, rowData) {
        if (cellData > Math.abs(rowData.balance) && rowData.balance < 0) {
          $(td).addClass('text-success');
        }
      },
    },
    ],
    paging: false,
  });

  // Scroll today into view
  $todayRow[0].scrollIntoView({
    behavior: 'auto',
    block: 'center',
    inline: 'center',
  });

  $('table.cashflow-table tbody').on('click', 'td', function update() {
    const tr = $(this).closest('tr');
    const row = table.row(tr);
    const rowData = row.data();
    if (rowData.movements.length !== 0) {
      if (row.child.isShown()) {
        row.child.hide();
        tr.removeClass('shown');
      } else {
        row.child(format(rowData)).show();
        tr.addClass('shown');
      }
    }
  });
});
