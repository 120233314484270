import InputHelpTextHandler from '../components/input-help-text';
import App from '../app';

$(() => {
  const nameSelector = '#id_name';
  const personSelector = '#id_person';
  const inputHelpTextHandler = new InputHelpTextHandler(nameSelector);

  function nameChanged() {
    const personNameAndRut = $(`${personSelector} option:selected`).text();
    const personName = personNameAndRut.substring(0, personNameAndRut.lastIndexOf(' '));
    const $nameInput = $(nameSelector);
    inputHelpTextHandler.updateText(`${personName} - ${$nameInput.val()}`);
  }

  $(nameSelector).keyup(nameChanged);
  $(personSelector).change(nameChanged);
  $(nameSelector).keyup();
});

$(() => {
  const grossAmountSelector = '#id_amount,#id_gross_amount';
  const currencySelector = '#id_currency';
  const taxApplicableId = 'id_tax_applicable';
  const taxRateId = 'id_tax_rate';
  const amountHelpTextHandler = new InputHelpTextHandler(grossAmountSelector);

  function setAmountsHelpTexts() {
    const taxApplicableInput = document.getElementById(taxApplicableId);
    if (taxApplicableInput === null) {
      return;
    }
    const isVatApplicable = taxApplicableInput.checked || taxApplicableInput.value === 'True';
    const grossAmount = parseFloat($(grossAmountSelector).val(), 10) || 0;
    const currency = $(currencySelector).val();

    const taxRateInput = document.getElementById(taxRateId);

    const taxRate = 1 + parseFloat(taxRateInput.value || 0);

    // multiply by 100 and divide by 100 to round by to decimals
    let netAmount = isVatApplicable ? grossAmount / taxRate : grossAmount;

    if (currency === 'CLP') {
      netAmount = Math.round(netAmount);
    } else {
      netAmount = Math.round((netAmount + Number.EPSILON) * 1000) / 1000;
    }

    const vat = Math.round((grossAmount - netAmount + Number.EPSILON) * 1000) / 1000;

    const text = `Neto: ${App.utils.thousandSeparator(netAmount)} ${currency}`;
    const vatText = isVatApplicable ? `Tax: ${App.utils.thousandSeparator(vat)} ${currency}` : '';

    amountHelpTextHandler.updateText(`${text}<br>${vatText}`);
  }

  $(grossAmountSelector).keyup(setAmountsHelpTexts);
  $(grossAmountSelector).change(setAmountsHelpTexts);
  $(currencySelector).change(setAmountsHelpTexts);

  // Place all non jquery code here
  function handleTaxApplicableChanged() {
    setAmountsHelpTexts();
    const taxApplicableInput = document.getElementById(taxApplicableId);
    const taxRateInput = document.getElementById(taxRateId);

    if (taxApplicableInput.checked || taxApplicableInput.value === 'True') {
      if (taxRateInput.value == 0) { // eslint-disable-line
        taxRateInput.value = taxRateInput.dataset.defaultTaxRate;
      }
    } else {
      taxRateInput.value = 0;
    }
    setAmountsHelpTexts();
  }

  document.getElementById(taxApplicableId).addEventListener(
    'change', handleTaxApplicableChanged, false);

  document.getElementById(taxRateId).addEventListener(
    'change', setAmountsHelpTexts, false);

  document.getElementById(taxRateId).addEventListener(
    'keyup', setAmountsHelpTexts, false);

  handleTaxApplicableChanged();
});
